import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  CardInfo,
  PaymentResourceService,
  StripePaymentService,
  StripeProductPriceService,
  StripeServiceType, SubscriptionStatus,
  VendorProfilService
} from '@cdq/api';
import {ModalController, Platform} from '@ionic/angular';
import {AlertService} from 'src/app/services/alert/alert.service';
import {KeycloakIonicService} from 'src/app/services/keycloak/keycloak-ionic.service';
import {AddCardComponent} from '../add-card/add-card.component';

declare var Stripe: any;
import * as AppConstants from '../../app.constants';
import * as moment from 'moment';
import {EnvService} from "../../services/environment/env.service";


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit {

  @Input("discount") discount: boolean;
  @Input("product") product: any;
  @Input("credit") credit: any;

  cardFound: boolean = false;
  cardList: CardInfo[] = [];
  card: CardInfo;

  stripe: any;
  elements: any;
  cardElement: any;
  remain: any;
  total: any;
  price: any;
  coupon: boolean = false;
  couponCode: string = ""
  tva: any;
  creditUsed: any;
  amount: any;
  subTotal: any;
  codeValidated: boolean = false;
  reduction: any;
  totalAfterCode: any;
  isApp: boolean = false;
  canHaveTrial: boolean = false;
  displayTrialStuff: boolean = false;
  alreadyHasSub: boolean = false;
  nextMonth: any;

  constructor(
    private modalCtrl: ModalController,
    private paymentService: PaymentResourceService,
    private keycloakService: KeycloakIonicService,
    private alertService: AlertService,
    private stripePaymentService: StripePaymentService,
    private stripeProductPriceService: StripeProductPriceService,
    private platform: Platform,
    private vendorProfilService: VendorProfilService,
    private envService: EnvService,
  ) {
    this.stripe = Stripe(this.envService.stripePublicKey);
  }

  addCoupon(): void {
    this.coupon = !this.coupon;
    if (this.coupon == false) {
      this.couponCode = "";
    }
  }

  isFloat(number): boolean {
    if (number.toString().includes('.')) {
      return true;
    } else {
      return false;
    }
  }

  fixedNumber(number: any, nb?: number): number {
    if (this.isFloat(number)) {
      let indexOfDot = number.toString().indexOf('.');
      return number.toString().slice(0, indexOfDot + 3);
    } else {
      return number;
    }
  }

  ngOnInit(): void {
    this.isApp = this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb');
    this.nextMonth = moment().locale('fr').add(3, 'months');

    this.vendorProfilService.vendorProfileLoad().subscribe(vendor => {
      console.log('[payment] (ngOnInit) vendorPofilLoad', vendor);
      this.canHaveTrial = vendor.result.account.canHaveTrial != null ? vendor.result.account.canHaveTrial : true;
      // IF STARTER HAVE TRIAL OR NOT
      // this.canHaveTrial = this.product.product.name == 'Pro +' ? true : false;

      this.stripePaymentService.clientSubscription().subscribe((r) => {
        console.log('[paylment page] clientSubscription', r);
        if (r.result) {
          let subscribed = r.result;
          if (subscribed.endAt) {}
          if (subscribed && subscribed.subscriptionStatus == SubscriptionStatus.Trial) {
            this.displayTrialStuff = true;
            this.alreadyHasSub = true;
          }
        }

        this.stripeProductPriceService
          .productPricesList()
          .subscribe((productPrices) => {
            let pro;
            let starter;
            productPrices.items.forEach((product) => {
              if (product.product.name == 'Pro +') {
                pro = product;
                pro.product.metadata = Object.values(
                  pro.product.metadata
                ).map((value) => value);
              } else if (product.product.name == 'Starter') {
                starter = product;
                starter.product.metadata = Object.values(
                  starter.product.metadata
                ).map((value) => value);
              }
            });
          });
      });


      // this.credit = this.fixedNumber(this.credit);
      console.log(this.tva)
      this.credit = this.credit.toFixed(2);
      this.price = this.product.prices[0].unitAmount / 100;
      this.tva = this.price * 0.2;
      if (this.credit > 0) {
        if (this.credit >= this.price) {
          this.creditUsed = this.price;
          this.tva = 0;
          this.subTotal = this.tva;
          this.total = this.tva;
          this.remain = this.credit - (this.price);
        } else if (this.credit < this.price) {
          this.remain = 0;
          this.creditUsed = this.credit;
          this.subTotal = this.price - this.creditUsed;
          this.tva = this.subTotal * 0.2;
          this.total = this.subTotal + this.tva;
        }
      } else {
        this.total = this.price + this.tva;
        this.subTotal = this.total;
        this.creditUsed = 0;
        this.remain = 0;
      }

      if (this.canHaveTrial) {
        this.creditUsed = 3 * this.price;
        this.subTotal = 0;
        this.tva = 0;
      }

      this.refresh();

    })
  }

  onChangeAmount(): void {
    console.log(this.amount);
    if (this.amount >= 1) {
      this.subTotal = (this.amount * 1.2).toFixed(2);
      this.total = (this.amount * 1.2).toFixed(2);
    } else {
      this.subTotal = 0.00.toFixed(2);
      this.total = 0.00.toFixed(2);
    }
  }

  refresh(): void {
    this.keycloakService.isLoggedIn().then(connected => {
      if (connected) {
        this.paymentService.listCreditCards().subscribe(r => {
          console.log(r);
          if (r.items.length > 0) {
            this.cardFound = true;
            this.cardList = r.items;
          }
          for (let item of r.items) {
            if (item.defaultCard) {
              this.card = item;
            }
          }
        });
      }
    })
  }

  ngAfterViewInit(): void {
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card-element');

    console.log("CARD ELEMENT: ", this.cardElement);

    this.cardElement.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  validateCredit(): void {
    this.alertService.ask("Paiement", "Voulez-vous valider le paiement ?", "Oui, valider", () => {
      this.alertService.loadWithMessage("Validation du paiement ...").then(() => {
        if (this.cardList.length == 0 && !this.card) {
          this.stripe.createToken(this.cardElement).then(createToken => {
            if (createToken.error) {
              console.log('Error creating payment method.');
              const errorElement = document.getElementById('card-errors');
              errorElement.textContent = createToken.error.message;
              this.alertService.temp("Une erreur est survenue lors de l'ajoute de votre carte, veuillez réessayez ultérieurement.", 3000);
            } else {
              console.log('[payment] Token acquired!', createToken.token.card.id);
              this.paymentService.addCreditCard({
                cardId: createToken.token.card.id,
                defaultCard: true,
              }).forEach(addCreditCard => {
                console.log("AddCreditCard", addCreditCard);
                this.paymentService.defaultCard(addCreditCard.result).subscribe(defaultCard => {
                  console.log("Default Card", defaultCard);
                  this.stripePaymentService.paymentIntent({
                    credits: this.amount,
                    cardId: addCreditCard.result,
                    discount: false,
                    service: StripeServiceType.Wallet,
                    promotionCode: this.couponCode != "" ? this.couponCode : null,
                  }).subscribe(paymentIntent => {
                    console.log("Payment Intent", paymentIntent);
                    this.stripe.confirmCardPayment(paymentIntent.result).then(confirmCardPayment => {
                      console.log("Confirm card payment", confirmCardPayment);
                      if (confirmCardPayment.error) {
                        if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                          this.alertService.closeLoader();
                          this.alertService.temp("Paiement échoué !", 3000, "alert-danger");
                          this.modalCtrl.dismiss();
                        } else {
                          this.alertService.closeLoader();
                          this.alertService.temp("Paiement réussi ! Cela peut prendre quelques instants ...", 3000, "alert-info");
                          this.modalCtrl.dismiss();
                        }
                      } else {
                        this.alertService.closeLoader();
                        this.alertService.temp("Paiement réussi ! Cela peut prendre quelques instants ...", 3000, "alert-info");
                        this.modalCtrl.dismiss();
                      }
                    }).catch(error => {
                      console.log(error);
                    })
                  })
                })
              })
            }
          })
        } else {
          this.paymentService.defaultCard(this.card.id).subscribe(defaultCard => {
            console.log("Default Card", defaultCard);
            this.stripePaymentService.paymentIntent({
              cardId: this.card.id,
              service: StripeServiceType.Wallet,
              promotionCode: this.couponCode != "" ? this.couponCode : null,
              credits: this.amount,
              discount: false,
            }).subscribe(paymentIntent => {
              console.log("Payment Intent", paymentIntent);
              this.stripe.confirmCardPayment(paymentIntent.result).then(confirmCardPayment => {
                console.log("Confirm card payment", confirmCardPayment);
                if (confirmCardPayment.error) {
                  if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                    this.alertService.closeLoader();
                    this.alertService.temp("Paiement échoué !", 3000, "alert-danger");
                    this.modalCtrl.dismiss();
                  } else {
                    this.alertService.closeLoader();
                    this.alertService.temp("Paiement réussi ! Cela peut prendre quelques instants ...", 3000, "alert-info");
                    this.modalCtrl.dismiss();
                  }
                } else {
                  this.alertService.closeLoader();
                  this.alertService.temp("Paiement réussi ! Cela peut prendre quelques instants ...", 3000, "alert-info");
                  this.modalCtrl.dismiss();
                }
              }).catch(error => {
                console.log(error);
              })
            })
          })
        }
      })
    }, "Non, annuler", () => {
      this.modalCtrl.dismiss("error");
    })
  }

  validate(): void {
    this.alertService.ask("Paiement", "Voulez-vous valider le paiement ?", "Oui, valider", () => {
      this.alertService.loadWithMessage("Validation du paiement ...").then(() => {
        if (this.cardList.length == 0 && !this.card) {
          this.stripe.createToken(this.cardElement).then(createToken => {
            if (createToken.error) {
              console.log('Error creating payment method.');
              const errorElement = document.getElementById('card-errors');
              errorElement.textContent = createToken.error.message;
              this.alertService.temp("Une erreur est survenue lors de l'ajoute de votre carte, veuillez réessayez ultérieurement.", 3000);
            } else {
              console.log('[payment] Token acquired!', createToken.token.card.id);
              this.paymentService.addCreditCard({
                cardId: createToken.token.id,
                defaultCard: true,
              }).forEach(addCreditCard => {
                console.log("AddCreditCard", addCreditCard);
                if(!addCreditCard.result){
                  this.alertService.temp("Carte de paiment invalide. Veuillez vérifier les informations", 3000);
                } else {
                  this.paymentService.defaultCard(addCreditCard.result).subscribe(defaultCard => {

                    console.log("Default Card", defaultCard);
                    // ADD SUBSCRIPTION METHOD HERE
                    this.stripePaymentService.createSubscription({
                      cardId: addCreditCard.result,
                      discount: this.discount,
                      service: StripeServiceType.Subscription,
                      priceId: this.product.prices[0].id,
                      productId: this.product.product.id,
                      promotionCode: this.couponCode != "" ? this.couponCode : null,
                    }).subscribe(createSubscription => {
                      console.log('createSubscription', createSubscription);
                      if (createSubscription.status == false) {
                        this.stripe.confirmCardPayment(createSubscription.result).then(confirmCardPayment => {
                          console.log('confirmCardPayment', confirmCardPayment);
                          if (confirmCardPayment.error) {
                            if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                              this.alertService.closeLoader();
                              this.alertService.temp("Abonnement échoué !", 3000, "alert-danger");
                              this.modalCtrl.dismiss();
                            } else {
                              this.alertService.closeLoader();
                              this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                              this.modalCtrl.dismiss();
                            }
                          } else {
                            this.alertService.closeLoader();
                            this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                            this.modalCtrl.dismiss();
                          }
                        })
                      } else {
                        this.alertService.closeLoader();
                        this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                        this.modalCtrl.dismiss();
                      }
                    })
                  })
                }

              })
            }
          })
        } else {
          this.paymentService.defaultCard(this.card.id).subscribe(defaultCard => {
            console.log("Default Card", defaultCard);

            this.stripePaymentService.createSubscription({
              cardId: this.card.id,
              discount: this.discount,
              service: StripeServiceType.Subscription,
              priceId: this.product.prices[0].id,
              productId: this.product.product.id,
              promotionCode: this.couponCode != "" ? this.couponCode : null,
            }).subscribe(createSubscription => {
              console.log('createSubscription', createSubscription);
              if (createSubscription.status == false) {
                this.stripe.confirmCardPayment(createSubscription.result).then(confirmCardPayment => {
                  console.log('confirmCardPayment', confirmCardPayment);
                  if (confirmCardPayment.error) {
                    if (confirmCardPayment.error.paymentIntent.status != "succeeded") {
                      this.alertService.closeLoader();
                      this.alertService.temp("Abonnement échoué !", 3000, "alert-danger");
                      this.modalCtrl.dismiss();
                    } else {
                      this.alertService.closeLoader();
                      this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                      this.modalCtrl.dismiss();
                    }
                  } else {
                    this.alertService.closeLoader();
                    this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                    this.modalCtrl.dismiss();
                  }
                })
              } else {
                this.alertService.closeLoader();
                this.alertService.temp("Abonnement réussi !", 3000, "alert-success");
                this.modalCtrl.dismiss();
              }
            })
          })
        }
      })
    }, "Non, annuler", () => {
      this.modalCtrl.dismiss("error");
    })
  }

  changeCard(cardSelected: CardInfo): void {
    this.card = cardSelected;
  }

  async openCardModal() {
    const modal = await this.modalCtrl.create({
      component: AddCardComponent,
      cssClass: 'modal-payment',
    });
    modal.onDidDismiss().then(value => {
      console.log(value);
      if (value.data) {
        this.changeCard(value.data);
      }
    })
    return await modal.present();
  }

  dismissModal(): void {
    this.modalCtrl.dismiss();
  }

  applyCode(): void {
    if (this.amount > 0) {
      this.stripePaymentService.applyCode(this.couponCode).subscribe(applyCode => {
        console.log("ApplyCode", applyCode);
        if (applyCode.status) {
          if (applyCode.result) {
            if (applyCode.result.amount > this.amount) {
              this.alertService.infos("Information", `Le montant de votre réduction est supérieur au montant de votre achat (Réduction de : ${applyCode.result.amount} €)`, "Compris");
            } else {
              this.reduction = applyCode.result.amount;
              this.alertService.loadWithMessage("Validation du code ...").then(() => {
                this.totalAfterCode = this.total - applyCode.result.amount;
                this.codeValidated = true;
                this.alertService.closeLoader();
              })
            }
          }
        } else {
          this.alertService.infos("Information", "Le code promotionnelle est invalide.", "Compris");
        }
      })
    } else {
      this.alertService.infos("Information", "Veuillez d'abord saisir le montant souhaité", "Compris");
    }
  }

}
